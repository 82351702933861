import { CtaTypeEnum } from "../interfaces";

export const MAX_ITEMS_ATTACHED_TO_CTA = 4;
export const IGNORED_SLUGS = ["seenspire-website-slider"];
export const HELP_URL = "https://help.seenspire.com/portal/kb/product/product";
export const SEENSPIRE_APP_IDS = {
  SLIDER_ITEM_EXPAND_ARROW: "SLIDER_ITEM_EXPAND_ARROW",
};
export const MAX_TRIAL_LICENCES = 5;
export const SEARCH_DEBOUNCE_DURATION = 450;
export const LOGO_TYPES = ["Image", "Text", "None"].map((item) => ({
  value: item,
  label: item,
}));

export const FONTS = [
  {
    label: "Default",
    value: "Default",
  },
  {
    label: "Neo Sans",
    value: "Neo Sans",
  },
  {
    label: "Merriweather",
    value: "Merriweather",
  },
  {
    label: "Arial",
    value: "Arial",
  },
  {
    label: "Courier New",
    value: "Courier New",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Lucida Sans Unicode",
    value: "Lucida Sans Unicode",
  },
  {
    label: "Palatino",
    value: "Palatino",
  },
  {
    label: "Tahoma",
    value: "Tahoma",
  },
  {
    label: "Times New Roman",
    value: "Times New Roman",
  },
  {
    label: "OpelNext",
    value: "OpelNext",
  },
  {
    label: "Trebuchet MS",
    value: "Trebuchet MS",
  },
  {
    label: "Verdana",
    value: "Verdana",
  },
  {
    label: "MS Sans Serif",
    value: "MS Sans Serif",
  },
  {
    label: "Open Sans",
    value: "Open Sans",
  },
  {
    label: "Lato",
    value: "Lato",
  },
  {
    label: "Arvo",
    value: "Arvo",
  },
  {
    label: "Vollkorn",
    value: "Vollkorn",
  },
  {
    label: "Aller",
    value: "Aller",
  },
  {
    label: "Bossa",
    value: "Bossa",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "FaktPro",
    value: "FaktPro",
  },
  {
    label: "Indie Flower",
    value: "Indie Flower",
  },
  {
    label: "Bree Serif",
    value: "Bree Serif",
  },
  {
    label: "Chirp",
    value: "Chirp",
  },
  {
    label: "Nexa",
    value: "Nexa",
  },
  {
    label: "FSLucas",
    value: "FSLucas",
  },
  {
    label: "Bangers",
    value: "Bangers",
  },
  {
    label: "Cantata One",
    value: "Cantata One",
  },
  {
    label: "PlutoSans",
    value: "PlutoSans",
  },
  {
    label: "CorporateACon",
    value: "CorporateACon",
  },
  {
    label: "FORsmart",
    value: "FORsmart",
  },
  {
    label: "ProximaNova",
    value: "ProximaNova",
  },
  {
    label: "TVNord",
    value: "TVNord",
  },
  {
    label: "Knockout",
    value: "Knockout",
  },
  {
    label: "euclidsquare",
    value: "euclidsquare",
  },
  {
    label: "Oswald",
    value: "Oswald",
  },
  {
    label: "shell",
    value: "shell",
  },
  {
    label: "LVSans",
    value: "LVSans",
  },
  {
    label: "NotoSans",
    value: "NotoSans",
  },
  {
    label: "verlag",
    value: "verlag",
  },
  {
    label: "Viga",
    value: "Viga",
  },
  {
    label: "Gotham",
    value: "Gotham",
  },
  {
    label: "Garamond",
    value: "Garamond",
  },
  {
    label: "Alta",
    value: "Alta",
  },
  {
    label: "Paramount",
    value: "Paramount",
  },
  {
    label: "Barlow",
    value: "Barlow",
  },
  {
    label: "Asics",
    value: "Asics",
  },
  {
    label: "Barlow",
    value: "Barlow",
  },
  {
    label: "HelveticaPro",
    value: "HelveticaPro",
  },
  {
    label: "Request font",
    value: "REQUEST_FONT",
  },
];

export const THEME_MODES = [
  {
    label: "Light",
    value: "Light",
  },
  {
    label: "Dark",
    value: "Dark",
  },
];

export const SINGLE_SLIDE_OPTIONS = [
  {
    label: "Single slide",
    value: "true",
  },
  {
    label: "Multiple slides",
    value: "false",
  },
];

export const PERFORMANCE_OPTIONS = [
  {
    label: "Auto adjust",
    value: "auto",
  },
  {
    label: "Full range animation",
    value: "main",
  },
  {
    label: "Minimal animations",
    value: "basic",
  },
  {
    label: "No animations",
    value: "low",
  },
];

export const DISPLAY_SETTING_OPTIONS = [
  {
    label: "Default",
    value: "Default",
  },
  {
    label: "Only Media",
    value: "OnlyMedia",
  },
];
export const TEAMS_DISPLAY_SETTING_OPTIONS = [
  {
    label: "Disabled",
    value: "Default",
  },
  {
    label: "Enabled",
    value: "OnlyMedia",
  },
];

export const SHOW_HIDE_OPTIONS = [
  {
    label: "Show",
    value: "true",
  },
  {
    label: "Hide",
    value: "false",
  },
];

export const PROFILE_POSITION_OPTIONS = [
  {
    label: "Top",
    value: "Top",
  },
  {
    label: "Bottom",
    value: "Bottom",
  },
];

export const ENABLED_DISABLED_OPTIONS = [
  {
    label: "Disabled",
    value: "false",
  },
  {
    label: "Enabled",
    value: "true",
  },
];

export const CONTENT_ORDER_OPTIONS = [
  {
    label: "Most recent",
    value: "false",
  },
  {
    label: "Shuffled",
    value: "true",
  },
];

export const IMAGE_BG_MODE_OPTIONS = [
  {
    label: "Enabled",
    value: "Fit",
  },
  {
    label: "Disabled",
    value: "Full",
  },
];

export const TIME_PER_SLIDE_OPTIONS = [
  {
    label: "5 Seconds",
    value: "5",
  },
  {
    label: "10 Seconds",
    value: "10",
  },
  {
    label: "15 Seconds",
    value: "15",
  },
];

export const LOGO_SCALE_OPTIONS = [
  {
    label: "Small",
    value: "Small",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Large",
    value: "Large",
  },
];

export const MEDIA_TYPES_GRID_OPTIONS = [
  {
    label: "Display any type",
    value: "ALL",
  },
  {
    label: "Display images",
    value: "IMAGES",
  },
  {
    label: "Display text",
    value: "TEXT",
  },
  {
    label: "Display videos",
    value: "VIDEOS",
  },
  {
    label: "Display text & images",
    value: "TEXT_IMAGES",
  },
  {
    label: "Display images & video",
    value: "IMAGES_VIDEOS",
  },
  {
    label: "Display text & video",
    value: "TEXT_VIDEOS",
  },
];

export const CONTENT_OPTIONS = [
  {
    label: "Headline",
    value: "Headline",
    description: "Display only headlines",
  },
  {
    label: "Headline & Story",
    value: "HeadlineStory",
    description: "Display both headlines and their stories",
  },
  {
    label: "Story",
    value: "Story",
    description: "Ignore headlines and display stories only",
  },
];

export const WEATHER_FORECAST_CONTENT_OPTIONS = [
  {
    label: "Forecast only",
    value: "Headline",
    description: "Display weather forecast only",
  },
  {
    label: "Full",
    value: "HeadlineStory",
    description: "Display Forecasted and today`s weather",
  },
  {
    label: "Today`s weather",
    value: "Story",
    description: "Display the weather of today only",
  },
];

export const CONTENT_SIZE_OPTIONS = [
  {
    label: "Small",
    value: "small",
    description: "Show content in smaller columns",
  },
  {
    label: "Large",
    value: "large",
    description: "Show content in larger columns",
  },
];

export const CONTENT_RATIO_OPTIONS = [
  {
    label: "Standard",
    value: "standard",
    description: "Standard - 2 columns layout",
  },
  {
    label: "Extended",
    value: "extended",
    description: "Extended - 3 columns layout (recommended for widescreens)",
  },
  // @TODO Check if this is still used
  // {
  //   label: "Custom",
  //   value: "custom",
  //   description: 'Specific 3 columns layout available for only one customer'
  // },
];

export const STORY_FREQUENCY_OPTIONS = [
  {
    label: "Fixed",
    value: "0",
  },
  {
    label: "Every 5 stories",
    value: "5",
  },
  {
    label: "Every 10 stories",
    value: "10",
  },
  {
    label: "Every 20 stories",
    value: "20",
  },
];

export const IMAGE_MODE_OPTIONS = [
  {
    label: "Enabled",
    value: "Fit",
  },
  {
    label: "Disabled",
    value: "Full",
  },
];

export const POST_FREQUENCY_OPTIONS = [
  {
    label: "Always visible",
    value: "0",
  },
  {
    label: "Every 5 posts",
    value: "5",
  },
  {
    label: "Every 10 posts",
    value: "10",
  },
  {
    label: "Every 20 posts",
    value: "20",
  },
];

export const FAVPOST_FREQUENCY_OPTIONS = [
  {
    label: "Every 5 posts",
    value: "5",
  },
  {
    label: "Every 10 posts",
    value: "10",
  },
  {
    label: "Every 20 posts",
    value: "20",
  },
];

export const CTA_TYPES = [
  {
    label: "Follow us",
    value: CtaTypeEnum.follow,
    description: "Add your social media accounts with follow us sentence",
  },
  {
    label: "Custom message",
    value: CtaTypeEnum.custom,
    description: "Custom message of your choice",
  },
  {
    label: "Hashtag campaign",
    value: CtaTypeEnum.hashtag,
    description:
      "Add one of your social media accounts with a hashtag sentence",
  },
];

export const BACKGROUND_TYPES = [
  {
    label: "Color",
    value: "color",
  },
  {
    label: "Image",
    value: "image",
  },
];

export const CURATION_TYPES_OPTIONS = [
  { label: "feeds.curation_type_automatic", value: "AUTO_APPROVE" },
  { label: "feeds.curation_type_manual", value: "MANUAL" },
];

export const WEBEX_ONLY_MY_POSTS = [
  { label: "All posts", value: "false" },
  { label: "feeds.my_posts_only", value: "true" },
];

export const hidePostsOptions = [
  { label: "feeds.show_all_posts", value: "-1" },
  { label: "feeds.one_day_old", value: "1" },
  { label: "3 days old", value: "3" },
  { label: "feeds.one_week_old", value: "7" },
  { label: "2 weeks old", value: "14" },
  { label: "3 weeks old", value: "21" },
  { label: "feeds.one_month_old", value: "30" },
  { label: "2 months old", value: "60" },
  { label: "feeds.three_months_old", value: "90" },
  { label: "feeds.six_months_old", value: "180" },
  { label: "feeds.one_year_old", value: "365" },
];

export type SHOW_CTA_OPTION = {
  label: string;
  value: string;
};

export const SHOW_CTA_OPTIONS = [
  { label: "No", value: "no" },
  { label: "Yes", value: "yes" },
];

export const OTHER_LANGUAGE_CODES = [
  // doesn't include EN
  "FR",
  "NL",
  "DE",
  "AR",
  "ES",
  "IT",
  "PT",
  "SV",
  "SE",
  "NO",
  "FI",
  "IS",
];
