import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  App,
  styled,
  Block,
  createGlobalStyle,
  ProgressBarStyles,
} from "../seenspire-library";
import { Header, PoweredBy } from "./components";
import Routes, { RoutesConfig } from "./routes";
import { Provider, Consumer, IAppContext } from "./state";
import { AppMenu } from "./components/AppMenu/AppMenu";
import ConfirmEmailModal from "./modals/ConfirmEmailModal";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ErrorNotificationModal from "./modals/ErrorNotificationModal";
import NotificationModal from "./modals/NotificationModal";
import RequestFeatureModal from "./modals/RequestFeatureModal";
import SwitchAccountModal from "./modals/SwitchAccountModal";
import StickyNotification from "./components/StickyNotification/StickyNotification";
import DeleteFeedModal from "./modals/DeleteFeedModal";
import AcceptTermsModal from "./modals/AcceptTermsModal";
import RequestTrialModal from "./modals/RequestTrialModal";
import { RequestTrialEndedModal } from "./modals/RequestTrialModal/RequestTrialEndedModal";
import NoMobileImg from "../assets/mobile_notice_icon.svg";
import NoMobileLogo from "../assets/no_mobile_logo.svg";

const AppGlobalStyles = createGlobalStyle`
  html, body, #root {
    height:100%
  }
  #root {
    display:flex;
    flex-direction:column;
  }
  body{
    overflow-x: hidden;
    line-height: normal;
  }
  .uppy-Dashboard-AddFiles {
    border: none !important;
  }

  .uppy-Dashboard-AddFiles-list {
    flex: none !important;
  }
  .uppy-Dashboard-AddFiles-title {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    letter-spacing: -0.145px !important;
    text-align: center;
  }
  .uppy-Dashboard-inner {
    border: none !important;
  }
`;

const RouteContainer = styled(Block)`
  flex: 1;
`;

const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: -webkit-fill-available; /* Для iOS */
  background: #0f1c2f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  text-align: center;
  font-family: Arial, sans-serif;
`;

const Logo = styled.div`
  font-size: 2rem;
  position: absolute;
  left: 14%;
  top: -20px;
  background: #0f1c2f;
  padding-right: 20px;
  padding-left: 20px;
`;

const Icon = styled.div`
  width: 100px;
  height: 100px;
  margin-top: 85px !important;
  margin-bottom: 45px !important;
  background: #1d4ed8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  color: white;
  font-size: 2rem;
  margin: 0 auto;
`;

const Message = styled.div`
  margin-top: 75px;
  color: var(--e-7-eaed, #e7eaed);
  text-align: center;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.145px;
`;

const SubMessage = styled.div`
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BorderBlock = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid rgba(206, 212, 218, 0.3);
`;

const Index = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkIfMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    setIsMobile(/android|iPhone|iPad|iPod|windows phone/i.test(userAgent));
  };

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  if (isMobile) {
    return (
      <MobileOverlay>
        <BorderBlock>
          <Logo>
            <img src={NoMobileLogo} alt="NoMobileLogo" />
          </Logo>

          <div style={{ margin: "95px 35px" }}>
            <Icon>
              <img src={NoMobileImg} alt="NoMobileImg" />
            </Icon>
            <Message>Mobile not supported</Message>
            <SubMessage>
              Please access this app <br /> from a desktop or laptop.
            </SubMessage>
          </div>
        </BorderBlock>
      </MobileOverlay>
    );
  }

  return (
    <Router>
      <DragDropContextProvider backend={HTML5Backend}>
        <Provider>
          <Consumer>
            {(context: IAppContext) => (
              <App theme={context.theme}>
                <AppGlobalStyles />
                <ProgressBarStyles />
                <Suspense fallback={<></>}>
                  <StickyNotification />
                  <Header />
                  <AppMenu />
                </Suspense>
                <Suspense fallback={null}>
                  <RouteContainer>
                    <Routes routesConfig={RoutesConfig} />
                  </RouteContainer>
                  <NotificationModal isOpen={true} />
                  <ConfirmEmailModal isOpen={true} />
                  <AcceptTermsModal isOpen={true} />
                  <RequestFeatureModal isOpen={true} />
                  <RequestTrialModal isOpen={true} />
                  <RequestTrialEndedModal isOpen={true} />
                  <SwitchAccountModal isOpen={true} />
                  <DeleteFeedModal />
                  {context.showPoweredBy && <PoweredBy />}
                  <ErrorNotificationModal
                    error={context.error}
                    setError={context.setError}
                  />
                </Suspense>
              </App>
            )}
          </Consumer>
        </Provider>
      </DragDropContextProvider>
    </Router>
  );
};

export default Index;
